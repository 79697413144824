<template lang="pug">
  section.content-header
    .container-fluid
      .row.mb-2
        .col-sm-4
          h1 {{ $t(viewTranslation('title')) }}

        .col-sm-8
          breadcrumb

    section.content
      .container-fluid
        .card.card-default.mt-3.mb-3(v-if="hasToolbar()")
          .card-body.p-2
            .row
              .ml-auto.mr-2
                button(type="button" class="btn btn-sm btn-primary" @click="add()")
                  fa(icon="plus")
                  = ' '
                  | {{ $t('toolbar.add.label') }}

                button.ml-2(type="button" class="btn btn-sm btn-primary" @click="refresh()" :disabled="syncing")
                  fa(icon="sync")
                  = ' '
                  | {{ $t('toolbar.sync.label') }}

        router-view(ref="list")
</template>

<script>
import _ from 'lodash';
import Breadcrumb from "@/components/common/Breadcrumb";

export default {
  components: {
    Breadcrumb
  },

  data() {
    return {
      syncing: false,
      toolbar: false
    }
  },

  methods: {
    viewTranslation(property) {
      return `views.${this.$route.name}.${property}`;
    },

    hasToolbar() {
      const meta = this.$route.meta;

      if(!_.has(meta, 'toolbar')) {
        return false;
      }

      return meta.toolbar;
    },

    add() {
      this.$refs.list.add();
    },

    refresh() {
      this.syncing = true;
      this.$refs.list.load().finally(() => this.syncing = false);
    }
  }
}
</script>

<i18n>
{
  "es": {
    "toolbar": {
      "add": {
        "label": "Nuevo"
      },
      "sync": {
        "label": "Actualizar"
      }
    }
  }
}
</i18n>
